import React from "react"
import Layout from "../components/layout"
import Footer from "../components/footer"
import bodyStyles from "../styles/rayonnement.module.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import buttonVoir from "../Images/SVG_web_illustrations/DTT_Bouton_noir.svg"
import { useTranslation } from "../hooks/useTranslation"

const Link = ({ title, children, href }) => {
    return (
        <div className={bodyStyles.link}>
            <a target="_blank" href={href}>
                {title}
                <FontAwesomeIcon color="black" icon={faArrowUp} size="1x"/>
            </a>
            {children}
        </div>
    )
}

const RayonnementPage = () => {

    const t = useTranslation();

    return (
        <div>
            <Layout>
                <div className={bodyStyles.body}>

                    <h1>{t("rayonnement.rayonnement")}</h1>

                    <div className={bodyStyles.content_holder}>
                        <h3 className={bodyStyles.yellow}>{t("rayonnement.revue")}</h3>
                        <div className={bodyStyles.link_holder}>
                            <Link title="La Presse" href="https://www.lapresse.ca/actualites/sante/201901/06/01-5210122-une-premiere-clinique-pediatrique-mobile-a-montreal-dici-2020.php?fbclid=IwAR1pB8dBF2VwYgwmH94m4Bkh129otl5MCh1DSp9-xVIGvM0THg0kN_E4Byw"/>
                            <Link title="Le Specialiste - FMSQ" href="http://lespecialistekiosk.milibris.com/share/article/d7884c21-553b-4ec7-ace1-c3a7ff9de309/2d524d45-ae5d-4f71-a380-93277e47800a"/>
                            <Link title="TPL - Moms" href="https://tplmoms.com/2019/01/09/montreal-aura-peut-etre-une-premiere-clinique-pediatrique-mobile-dici-2020/?fbclid=IwAR1ScSky1QM9K48L57ijj0cWS_BZxaF0r6Y99CjGT9K0KhiWASNalz_1wsc"/>
                            <Link title="HEC Montréal" href="https://mag.hec.ca/place-aux-entrepreneurs-en-sante/">
                                <ul>
                                    <li>
                                        <a href="https://ideos.hec.ca/actualites/equipe-gagnante-au-tocquville-challenge-ma-mobiclinique/" target="_blank">
                                            ideos
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.hec.ca/nouvelles/2018/nos-etudiants-s-illustrent-en-france-avec-un-projet-social-innovant-de-clinique-pediatrique-mobile.html" target="_blank">
                                            hec.ca
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://mag.hec.ca/place-aux-entrepreneurs-en-sante/" target="_blank">
                                            HEC Mag
                                        </a>
                                    </li>
                                </ul>
                            </Link>
                        </div>
                    </div>

                    <div className={bodyStyles.content_holder}>
                        <h3 className={bodyStyles.purple}>{t("rayonnement.radio")}</h3>
                        <div className={bodyStyles.link_holder}>
                            <Link title="Émission Les éclaireurs sur ICI Premiere à la chaine de Radio-Canada" href="https://ici.radio-canada.ca/premiere/emissions/les-eclaireurs/segments/entrevue/101479/ma-mobiclinique-pediatrie-service-quartiers-defavorises-montreal-clinique-mobilite"/>
                            <Link title="CTV News" href="https://montreal.ctvnews.ca/a-new-mobile-clinic-hopes-to-help-serve-montreal-families-1.4334724"/>
                        </div>
                    </div>

                    <div className={bodyStyles.content_holder}>
                        <h3 className={bodyStyles.green}>{t("rayonnement.communaute")}</h3>
                        <div className={bodyStyles.link_holder}>
                            <Link title="Je fais Mtl" href="https://fairemtl.ca/fr/ma-mobiclinique"/>
                            <Link title="Hacking Health" href="https://hacking-health.org/cooperathon/"/>
                        </div>
                    </div>

                    <div className={bodyStyles.content_holder}>
                        <h3 className={bodyStyles.orange}>{t("rayonnement.competition")}</h3>
                        <div className={bodyStyles.link_holder}>
                            <Link title="McGill Dobson Cup 2019 -" href="https://dobsonchronicles.com/2019/02/25/mcgill-dobson-cup-finals-2019/">
                                <ul>
                                    <li>{t("rayonnement.socialBusiness")}</li>
                                </ul>
                            </Link>
                            <Link title="OSEntreprendre - Prix du public et Catégorie Services aux individus">
                                <ul>
                                    <li>
                                        <a href="https://www.youtube.com/watch?v=XWeHZygOX6o" target="_blank">
                                            Youtube
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://eequebec.com/communique-des-entreprises-montrealaises-emergentes-recompensees-lors-du-21e-gala-regional-du-defi-osentreprendre/" target="_blank">
                                            École des Entrepreneurs du Québec
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.lenouvelliste.ca/actualites/le-fil-groupe-capitales-medias/prix-du-public-du-defi-osentreprendre-a-vos-clics-a4dc638e79258f355aa99bf0e1cc4840" target="_blank">
                                            Le Nouvelliste
                                        </a>
                                    </li>
                                </ul>
                            </Link>
                        </div>
                    </div>

                </div>
            </Layout>

            <Footer color="#1b75bc" />
        </div>
    )
}

export default RayonnementPage